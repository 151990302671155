<template>
	<div>
		<h5>Product Details</h5>
		<a-divider />
		<div class="dF fw">

			<a-form-model ref="step3" :model="step3" class="w-1/2" v-if="instance.productType === 'lowrise'">

				<a-form-model-item label="Select Lot" prop="lot" :rules="req('Please select a Lot')">
					<a-tooltip v-if="Object.values(this.$route.query).length > 0 && this.$route.query.o && foundLot"
						overlayClassName="change-tooltip-color">
						<template slot="title">
							In order to change the lot, please go back to CRM Opportunities and select your desired lot.
						</template>
						<a-icon type="question-circle"
							style="line-height: 25px; margin-top: 5px; color: var(--orange); position: absolute; z-index: 2; top: -43px; left: 75px;" />
					</a-tooltip>
					<a-select :size="$mq === 'lg' ? null : 'large'" v-model="step3.lot"
						:disabled="Object.values(this.$route.query).length > 0 && this.$route.query.o && foundLot">
						<a-select-option :value="lot.id" v-for="lot in lots" :key="lot.id">
							Lot {{ lot.name }} <span v-if="lot.block">(Block {{ lot.block }})</span> <span
								v-if="lot.status && lot.status !== 'available'"
								style="background-color:orange; font-size:12px; ">({{
									lot.status[0].toUpperCase() +
									lot.status.substr(1)
								}})</span>
						</a-select-option>
					</a-select>
				</a-form-model-item>

				<a-form-model-item label="Select the Model Elevation" v-if="step3.lot && units && units.length"
					prop="unit" :rules="req('Please select a Unit')">
					<a-tooltip v-if="Object.values(this.$route.query).length > 0 && this.$route.query.o && foundModel"
						overlayClassName="change-tooltip-color">
						<template slot="title">
							In order to change the model elevation, please go back to CRM Opportunities and select your
							desired model elevation.
						</template>
						<a-icon type="question-circle"
							style="line-height: 25px; margin-top: 5px; color: var(--orange); position: absolute; z-index: 2; top: -43px; left: 172px;" />
					</a-tooltip>
					<a-select :size="$mq === 'lg' ? null : 'large'" v-model="step3.unit"
						:disabled="Object.values(this.$route.query).length > 0 && this.$route.query.o && foundModel">
						<a-select-option :value="item.id" v-for="item in units" :key="item.id">
							{{ item.unitGroup.name }} - <em>{{ item.name }} </em> <span
								v-if="item.unitGroup.isStandingInventory">
								(Standing
								Inventory)</span>
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<p v-else-if="loadingUnits">Loading Units ...</p>
				<p v-else-if="(step3.unit === false || (units.length === 0 && step3.lot)) && !loadingUnits"
					class="text-danger">There
					are no available units for this lot. Please select another lot.</p>

				<a-form-model-item label="Select the Floorplan Package" v-if="packages && packages.length > 1"
					prop="package" :rules="req('Please select a Unit Floorplan Package')">
					<a-select :size="$mq === 'lg' ? null : 'large'" v-model="step3.package">
						<a-select-option :value="item.id" v-for="item in packages" :key="item.id">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>

				<div v-if="step3.unit && bricks && bricks.length">
					<a-form-model-item label="Select Exterior Colour Package" prop="brick">
						<a-select :size="$mq === 'lg' ? null : 'large'" v-model="step3.brick">
							<a-select-option :value="item.id" v-for="item in bricks" :key="item.id">
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-form-model-item>
				</div>


				<template v-if="step3.unit && currentUnit.id">
					<a-divider />

					<a-form-model-item v-if="transaction.type == 'purchase'" label="Suggested Price (MSRP)">
						<a-tooltip overlayClassName="change-tooltip-color">
							<template slot="title">
								This is the Manufacturer's Suggested Retail Price
								<p class="mt-2">Lot Premiums: ${{ formatPrice(lotPremiums) }}<br />
									Model Price: ${{ currentPackage && formatPrice(currentPackage.price) }}
									<span v-if="reservationAmount"><br />Lot Reservation Fee:
										-${{ formatPrice(reservationAmount) }}</span>
								</p>
							</template>
							<a-input-number :disabled="true" style="width:300px; max-width:100%;" :value="msrp"
								:formatter="value => '$ ' + formatPrice(value)" />
						</a-tooltip>

					</a-form-model-item>



					<template v-else-if="transaction.type === 'lease'">

						<a-form-model-item label="Tenancy Start Date" prop="tenancyStartDate"
							:rules="req('Please input the Tenancy Start Date')">
							<a-date-picker style="width:300px; max-width:100%;" v-model="step3.tenancyStartDate" />
						</a-form-model-item>

						<a-form-model-item label="Tenancy End Date" prop="tenancyStartDate"
							:rules="req('Please input the Tenancy Start Date')">
							<a-date-picker style="width:300px; max-width:100%;" v-model="step3.tenancyEndDate" />
						</a-form-model-item>
					</template>



					<template v-if="transaction.type == 'purchase'">
						<a-divider />

						<a-form-model-item :label="tarion ? `${closingLabel} Type (Tarion)` : `${closingLabel} Type`"
							prop="closingType">
							<a-select v-model="step3.closingType" style="width:300px; max-width:100%;">
								<a-select-option value="tentative">Tentative</a-select-option>
								<a-select-option value="firm">Firm</a-select-option>
							</a-select>
						</a-form-model-item>

						<a-form-model-item label="Irrevocable Date" prop="irrevocableDate"
							:rules="req('Please input the Irrevocable Date')">
							<div class="dF aC" style="gap: 25px">
								<a-input v-if="notApplicableDates.irrevocableDate" v-model="step3.irrevocableDate" style="width: 40%;"
									disabled />
								<a-date-picker v-else style="width:300px; max-width:100%;"
									v-model="step3.irrevocableDate" :disabled-date="disabledDate" />
								<a-checkbox v-model="notApplicableDates.irrevocableDate" @change="onNotApplicableChange($event, 'irrevocableDate')"> Not Applicable
								</a-checkbox>
							</div>
						</a-form-model-item>
						<a-form-model-item :label="closingDateLabel" prop="closingDate"
							:rules="req('Please input the Closing Date')">
							<a-date-picker v-model="step3.closingDate" style="width:300px; max-width:100%;"
								:disabled-date="disabledDate" @change="checkClosing" />
							<p v-if="closingDateError" class="text-danger">Closing Date cannot fall on a Holiday or a
								Weekend. Please review and try again</p>
						</a-form-model-item>


					</template>


				</template>



			</a-form-model>


			<a-form-model ref="step3" :model="step3" class="w-1/2" v-else-if="instance.productType === 'highrise'">

				<a-form-model-item label="Select Floor" prop="floor" :rules="req('Please select a Floor')">
					<a-select :size="$mq === 'lg' ? null : 'large'" v-model="step3.floor">
						<a-select-option :value="floor.id" v-for="floor in condoFloors" :key="floor.id"
							:disabled="floor.disabled || floor.sold">
							{{ floor.name }} <em v-if="floor.street"> ({{ floor.sold ? '- (SOLD OUT)' : '' }})</em>
						</a-select-option>
					</a-select>
				</a-form-model-item>

				<a-form-model-item label="Select Unit" v-if="step3.floor" prop="unit"
					:rules="req('Please select a Unit')">
					<a-select :size="$mq === 'lg' ? null : 'large'" v-model="step3.unit">
						<a-select-option v-for="item in floorUnits"
							:disabled="!['available', 'hold'].includes(item.salesStatus)" :value="item.id"
							:key="item.id">
							<em>Unit {{ item.unitNumber }}{{ item.name ? ` - ${(item.name)}` : '' }}</em>
						</a-select-option>
					</a-select>
				</a-form-model-item>

				<a-form-model-item label="Select the Floorplan Package" v-if="packages && packages.length > 1"
					prop="package" :rules="req('Please select a Unit Floorplan Package')">
					<a-select :size="$mq === 'lg' ? null : 'large'" v-model="step3.package">
						<a-select-option :value="item.id" v-for="item in packages" :key="item.id">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>

				<template v-if="step3.unit && currentUnit.id">
					<a-divider />

					<a-form-model-item v-if="transaction.type == 'purchase'" label="Suggested Price (MSRP)">
						<a-tooltip overlayClassName="change-tooltip-color">
							<template slot="title">
								This is the Manufacturer's Suggested Retail Price
								<p class="mt-2">
									Unit Premiums: ${{ formatPrice(unitPremiums) }}<br />
									Unit Price: ${{ currentPackage.price && formatPrice(currentPackage.price) }}
									<span v-if="reservationAmount"><br />Unit Reservation Fee:
										-${{ formatPrice(reservationAmount) }}</span>
								</p>
							</template>
							<a-input-number :disabled="true" style="width:300px; max-width:100%;" :value="msrp"
								:formatter="value => '$ ' + formatPrice(value)" />
						</a-tooltip>

					</a-form-model-item>

					<template v-else-if="transaction.type === 'lease'">

						<a-form-model-item label="Tenancy Start Date" prop="tenancyStartDate"
							:rules="req('Please input the Tenancy Start Date')">
							<a-date-picker style="width:300px; max-width:100%;" v-model="step3.tenancyStartDate" />
						</a-form-model-item>

						<a-form-model-item label="Tenancy End Date" prop="tenancyStartDate"
							:rules="req('Please input the Tenancy Start Date')">
							<a-date-picker style="width:300px; max-width:100%;" v-model="step3.tenancyEndDate" />
						</a-form-model-item>
					</template>

					<template v-if="transaction.type == 'purchase'">
						<a-divider />
						<a-form-model-item label="Closing Date Type (Tarion)" prop="firmClosing" v-if="tarion">
							<a-select v-model="step3.closingType" style="width:300px; max-width:100%;">
								<a-select-option value="tentative">Tentative</a-select-option>
								<a-select-option value="firm">Firm</a-select-option>
							</a-select>
						</a-form-model-item>
						<a-form-model-item label="Irrevocable Date" prop="irrevocableDate"
							:rules="req('Please input the Irrevocable Date')">
							<div class="dF aC" style="gap: 25px">
								<a-input v-if="notApplicableDates.irrevocableDate" v-model="step3.irrevocableDate" style="width: 40%;"
									disabled />
								<a-date-picker v-else style="width:300px; max-width:100%;"
									v-model="step3.irrevocableDate" :disabled-date="disabledDate" />
								<a-checkbox v-model="notApplicableDates.irrevocableDate" @change="onNotApplicableChange($event, 'irrevocableDate')"> Not Applicable
								</a-checkbox>
							</div>
						</a-form-model-item>
						<a-form-model-item :label="closingDateLabel" prop="closingDate"
							:rules="req('Please input the Occupancy Date')">
                            <div class="dF aC" style="gap: 25px">
                                <a-input v-if="notApplicableDates.closingDate" v-model="step3.closingDate" style="width: 40%;"
									disabled />
							    <a-date-picker v-else v-model="step3.closingDate" style="width:300px; max-width:100%;"
								    :disabled-date="disabledDate" @change="checkClosing" />
                                <a-checkbox v-model="notApplicableDates.closingDate" @change="onNotApplicableChange($event, 'closingDate')"> Not Applicable
                                    </a-checkbox>
                            </div>
							<p v-if="closingDateError" class="text-danger">Occupancy date cannot fall on a Holiday or a
								Weekend. Please review and try again</p>
						</a-form-model-item>
						<a-form-model-item v-if="tarion"
							:label="tarion ? `Outside ${closingLabel} Date (Tarion)` : `Outside ${closingLabel} Date`"
							prop="outsideClosing" :rules="req(`Please input the Outside Occupancy Date`)">
                            <div class="dF aC" style="gap: 25px">
                                <a-input v-if="notApplicableDates.outsideClosing" v-model="step3.outsideClosing" style="width: 40%;"
									disabled />
							    <a-date-picker v-else style="width:300px; max-width:100%;" v-model="step3.outsideClosing"
								    :disabled-date="disabledDate" />
                                <a-checkbox v-model="notApplicableDates.outsideClosing" @change="onNotApplicableChange($event, 'outsideClosing')"> Not Applicable
                                    </a-checkbox>
                            </div>
						</a-form-model-item>
						<a-form-model-item label="Early Termination Condition Date" prop="terminationDate"
							:rules="req('Please input the Early Termination Condition Date')">
							<div class="dF aC" style="gap: 25px">
								<a-input v-if="notApplicableDates.terminationDate" v-model="step3.terminationDate" style="width: 40%;"
									disabled />
								<a-date-picker v-else style="width:300px; max-width:100%;"
									v-model="step3.terminationDate" :disabled-date="disabledDate" />
								<a-checkbox v-model="notApplicableDates.terminationDate" @change="onNotApplicableChange($event, 'terminationDate')"> Not Applicable
								</a-checkbox>
							</div>
						</a-form-model-item>
					</template>
				</template>



			</a-form-model>



			<div class="w-1/2 dF jC pt-4 aC">
				<div style="max-width: 400px;flex:1; padding: 20px;border: 1px solid var(--very-light-purple);">
					<div v-if="!step3.unit">
						<div class="padding-box" style="height:200px; background:#f2f2f2;"></div>
						<a-skeleton :loading="true" />
					</div>
					<div v-else-if="currentUnit.id">
						<img :src="currentUnit.image" style="max-width:100%" />
						<div v-if="instance.productType == 'lowrise'">
							<div class="dF jSB" style="align-items:baseline">
								<span style="font-size:18px;">{{ currentUnit.unitGroup.name }} -
									{{ currentUnit.name }}</span>

							</div>
							<div class="dF jSB">
								<span>{{ currentPackage.beds }} Beds</span> |
								<span>{{ currentPackage.baths }} Baths</span> |
								<span>{{ currentPackage.garages }} Car Garage</span> |
								<span>{{ currentPackage.sqft }} {{ currentPackage.sqft2 && currentPackage.sqft2 > 0 ? `+
									${currentPackage.sqft2}` : '' }} Sq. Ft.</span>
							</div>
							<div v-if="brickChosen" class="mt-2">
								<hr />
								<div class="dF jSB aE">
									<p>Exterior Colour Package: {{ brickChosen.name }}</p>
									<img :src="brickChosen.image" style="width:100px;height:100px;object-fit:cover;" />
								</div>
							</div>
						</div>
						<div v-if="instance.productType == 'highrise'">
							<div class="dF jSB" style="align-items:baseline">
								<span style="font-size:18px;">{{ 'Unit #' + currentUnit.unitNumber }} -
									{{ currentUnit.name }}</span>

							</div>
							<div class="dF jSB">
								<span>{{ currentPackage.beds }} Beds</span> |
								<span>{{ currentPackage.baths }} Baths</span> |
								<span>{{ currentPackage.parking }} Parking</span> |
								<span>{{ currentPackage.sqft }} Sq. Ft.</span>
							</div>
							<div v-if="brickChosen" class="mt-2">
								<hr />
								<div class="dF jSB aE">
									<p>Exterior Colour Package: {{ brickChosen.name }}</p>
									<img :src="brickChosen.image" style="width:100px;height:100px;object-fit:cover;" />
								</div>
							</div>
						</div>

					</div>

				</div>

			</div>
		</div>

	</div>
</template>

<script>
import { findById } from 'bh-mod'
import { isWorkingDay, drawLot } from 'bh-mod/helper'
import moment from 'moment';

export default {
	props: ['transaction'],
	data: () => ({
        notApplicableDates: {
            irrevocableDate: false,
            closingDate: false,
			terminationDate: false,
            outsideClosing: false,
        },
		foundLot: false,
		foundModel: false,
		productNotFound: false,
		condoFloors: [],
		closingDateError: false,
		currentSiteplan: {},
		lots: [],
		units: [],
		bricks: [],
		selectedBricks: [],
		packages: [],
		highRisePremiums: {},
		loadingUnits: false,
		step3: {
			closingType: 'tentative',
			lot: '',
			floor: '',
			brick: '',
			unit: '',
			package: '',
			irrevocableDate: '',
			closingDate: '',
			terminationDate: '',
			outsideClosing: '',
			purchasePrice: 0,
			totalRent: 0,
			siteplanImage: '',
			lotImage: '',
			deposit: 0,
			tenancyStartDate: null,
			tenancyEndDate: null,
		},
        productChangeMessage: 'Altering your product details may require an update to your contract type. To review or confirm your contract selection, please click the back button to return to Step 1.'
	}),
	computed: {
		currentElevation() {
			return this.$store.state.appData.currentElevation
		},
		closingDateLabel() {
			if (this.instance.productType == 'lowrise') {
				if (this.step3.closingType == 'tentative') {
					return 'Closing Date (Tentative)'
				} else {
					return 'Closing Date (Firm)'
				}
			} else {
				if (this.step3.closingType == 'tentative') {
					return 'Occupancy Date (1st Tentative)'
				} else {
					return 'Occupancy Date (Firm)'
				}

			}
		},
		closingLabel() {
			return this.instance.productType === 'highrise' ? 'Occupancy' : 'Closing'
		},
		currentContract() {
			if (!this.transaction.contract) return {}
			return this.$store.state.appData.templates[this.transaction.template] || {}
		},
		floorUnits() {
			if (!this.step3.floor || !this.step3.floor.trim()) return []
			let floor = this.condoFloors.find(x => x.id === this.step3.floor)
			if (!floor) return false
			return floor.units.sort((a, b) => a.unitNumber.localeCompare(b.unitNumber));

		},
		brickChosen() {
			if (!this.step3.brick.trim()) return false
			return this.bricks.find(x => x.id === this.step3.brick)
		},
        tarion() {
            const { country = '', region = '' } = this.instance;
            return ['canada', 'ca'].includes(country.toLowerCase().trim()) && ['ontario', 'on'].includes(region.toLowerCase().trim());
        },

		instance() {
			let instance = this.$store.state.instance
			return instance
		},
		currentLot() {
			if (!this.step3.unit) return {}
			let found = findById(this.lots, this.step3.lot)

			if (!found) found = {}

			return found
		},
		msrp() {
			if (this.instance.productType === 'lowrise') {
				if (!this.step3.lot || !this.step3.unit || !this.currentLot || !this.currentLot.id || !this.currentPackage || !this.currentPackage.id) return 0
				return (this.currentPackage && this.currentPackage.price) + (this.lotPremiums) - this.reservationAmount
			} else {
				if (!this.currentPackage || !this.currentPackage.id) return 0
				return (this.currentPackage && this.currentPackage.price) + this.unitPremiums - this.reservationAmount
			}
		},
		lotPremiums() {
			let lot = this.currentLot
			let price = 0
			if (lot.other) {
				let premiums = Object.values(lot.other.premiums)
				if (Array.isArray(premiums)) {
					premiums.forEach(prm => {
						if (!prm.active) return
						if (prm.price && prm.price > 0) price += prm.price
					})

				}
			}
			return price
		},
		unitPremiums() {

			let unitPremiums = this.currentPackage.other && this.currentPackage.other.premiums || {}
			let price = 0

			Object.values(unitPremiums).filter(x => x).forEach(prm => {
				if (!prm.active) return
				if (prm.value) prm.value = +prm.value
				if (isNaN(prm.value)) return
				price += prm.value
			})


			return price
		},
		currentPackage() {
			if (!this.currentUnit || !this.currentUnit.packages || !this.step3.package) return {}
			return this.currentUnit.packages.find(pkg => pkg.id === this.step3.package) || (this.currentUnit.packages.length && this.currentUnit.packages[0]) || {}
		},
		currentUnit() {
			let units = this.units
			if (this.instance.productType === 'highrise') units = this.floorUnits
			if (!this.step3.unit) return {}
			let found = units && units.find(unit => unit.id === this.step3.unit)
			if (!found) found = {}

			return found
		},
		worksheet() {
			return this.$store.state.appData.worksheet
		},
		reservation() {
			return this.$store.state.appData.reservation
		},
		reservationAmount() {
			return (this.worksheet && this.worksheet.reservation && this.worksheet.reservation.amount) || (this.reservation && this.reservation.amount) || 0;
		},
		appSettings() {
			let app = this.$store.state.appData.allSettings.app;
			if (!app) app = { options: {} };
			return app.options;
		},
        isFromIPM() {
            // return true if router query has lot, elevation and package keys
            return this.$route.query.lot && this.$route.query.elevation && this.$route.query.package;
        },
    },
    watch:{
        msrp(val){
            this.step3.purchasePrice = val
            this.step3.totalRent = val
        },
        'step3.floor'(val){
            this.step3.unit = ''
        },
		async 'step3.unit'(val) {
            if (!val) return
            // show toast message if elevation is changed and it is from IPM but lot is not changed to avoid multiple messages
            if (this.isFromIPM && val != this.$route.query.elevation && this.$route.query.lot == this.step3.lot) {
                this.$message.warn(this.productChangeMessage, 5)
            }
			let units = this.units
			if (this.instance.productType === 'highrise') units = this.floorUnits
			if (val && units) {
				let unit = units.find(x => x.id === val && x.packages && x.packages.length)
				if (!unit) {
					return;
				}
				this.packages = [...unit.packages]
				this.step3.package =
                    (this.transaction.product?.unit?.id === this.step3.unit && this.transaction.product.unit.package?.id) ||
                    (this.reservation?.product?.unit?.id === this.step3.unit && this.reservation.product.unit.package?.id) ||
                    (this.worksheet?.unit?.id === this.step3.unit && (this.worksheet.package?.id || this.worksheet.package)) ||
                    unit.packages[0].id;

                const sParams = new URLSearchParams(location.search);
                const packageId = sParams.get('package');

                if (packageId) {
                    const pkg = this.packages.find(p => p.id === packageId);
                    if(pkg) {
                        this.step3.package = pkg.id
                    }
                }

				this.step3.purchasePrice = unit.packages[0].price
				this.step3.totalRent = unit.packages[0].price

				if (this.instance.productType !== 'highrise') return;

				let {data} = await this.$api.get(`/settings/:instance/condounits`);
				let {termination,closing,outside} = data?.options;

                if (unit.notApplicableDates?.closingDate) {
                    this.notApplicableDates.closingDate = true;
                    this.step3.closingDate = 'N/A';
                } else if (!this.notApplicableDates.closingDate && unit.packages[0].occupancy && new Date().getTime() < unit.packages[0].occupancy) {
                    const closingDate = moment(unit.packages[0].occupancy)
                    this.checkClosing(closingDate);
                    this.step3.closingDate = closingDate;
                } else if (!this.notApplicableDates.closingDate && closing.closingDate && new Date().getTime() < moment(closing.closingDate)) {
                    const closingDate = moment(closing.closingDate)
                    this.step3.closingDate = closingDate;
                }

                if (unit.notApplicableDates?.outsideClosing) {
                    this.notApplicableDates.outsideClosing = true;
                    this.step3.outsideClosing = 'N/A';
                } else if (!this.notApplicableDates.outsideClosing && unit.packages[0].outsideClosing && new Date().getTime() < unit.packages[0].outsideClosing) {
                    const outsideClosing = moment(unit.packages[0].outsideClosing)
                    this.checkClosing(outsideClosing);
                    this.step3.outsideClosing = outsideClosing;
                } else if (!this.notApplicableDates.outsideClosing && outside.outsideClosing && new Date().getTime() < moment(outside.outsideClosing)) {
                    const outsideClosing = moment(outside.outsideClosing)
                    this.step3.outsideClosing = outsideClosing;
                }

				if (!this.notApplicableDates.terminationDate){

					if (unit.notApplicableDates?.terminationDate){
						this.notApplicableDates.terminationDate = true;
						this.step3.terminationDate = 'N/A';
						return;
					}

					if (unit?.dynamicTerminationDate){
						let offerDate = new Date();
						if (unit.dynamicTerminationDateValue && unit.dynamicTerminationDateUnit){
							let terminationDate = moment(offerDate).add(unit.dynamicTerminationDateValue, unit.dynamicTerminationDateUnit);
							this.step3.terminationDate = terminationDate;
						}
					} else if (unit.terminationDate && new Date().getTime() < unit.terminationDate) {
						const terminationDate = moment(unit.terminationDate)
						this.step3.terminationDate = terminationDate;
					} else {
						if (termination?.dynamicTerminationDate){
							let offerDate = new Date();
							if (termination.dynamicTerminationDateValue && termination.dynamicTerminationDateUnit){
								let terminationDate = moment(offerDate).add(termination.dynamicTerminationDateValue, termination.dynamicTerminationDateUnit);
								this.step3.terminationDate = terminationDate;
							}
						} else if (termination?.terminationDate && new Date().getTime() < termination.terminationDate) {
							const terminationDate = moment(termination.terminationDate)
							this.step3.terminationDate = terminationDate;
						}
					}
				}
			}
		},
		'step3.lot'(val) {
			if (!val) return
            // show toast message if lot is changed and it is from IPM
            if (this.isFromIPM && val != this.$route.query.lot) {
                this.$message.warn(this.productChangeMessage, 5)
            }

			this.units = []
			this.bricks = []
			this.packages = []
			this.step3.package = ''
			this.step3.unit = ''
			this.step3.brick = ''

			this.loadingUnits = true
			this.$api.get(`/architectural-controls/:instance/availableunits/${val}${this.currentElevation ? '?unit=' + this.currentElevation : ''}`).then(async ({ data }) => {
				let units = data.units.filter(unit => {
					return unit.unitGroup?.id && unit.packages?.length
				})

				if (data.isArchitecturalRulesEnabled) {
					this.bricks = [{ id: "", name: "N/A" }, ...data.bricks];
				}

				if (this.transaction.product && this.transaction.product.unit && this.transaction.product.lot.id == this.step3.lot) {
					this.step3.unit = this.transaction.product.unit.id;
					this.step3.package = this.transaction.product.unit.package && this.transaction.product.unit.package.id || ''
				} else {
					this.step3.unit = ''
				}
				if (this.transaction.offer && this.transaction.offer.bricks && this.transaction.offer.bricks[0]) {
					this.step3.brick = this.transaction.offer.bricks[0].id
				}

				if (data.dynamicClosingDate){
					let offerDate = new Date();
					if (data.dynamicClosingDateValue && data.dynamicClosingDateUnit){
						let closingDate = moment(offerDate).add(data.dynamicClosingDateValue, data.dynamicClosingDateUnit);
						this.checkClosing(closingDate);
						this.step3.closingDate = closingDate;
					}
				} else if (data.closingDate && new Date().getTime() < data.closingDate) {
					const closingDate = moment(data.closingDate)
					this.checkClosing(closingDate);
					this.step3.closingDate = closingDate;
				}

				this.loadingUnits = false

				if (units.length === 0) return this.step3.unit === false

				this.currentSiteplan = data.siteplan

				this.units = units;
				let { product } = this.transaction.query;

                if (this.reservation?.product?.unit && this.reservation.product.lot.id === this.step3.lot) {
                    this.checkUnit(this.reservation.product.unit.id, this.reservation.product.unit.package?.id);
                } else if (this.worksheet?.unit && this.worksheet.lot.id === this.step3.lot) {
                    this.checkUnit(this.worksheet.unit.id, this.worksheet.package?.id);
                } else if (product && val === this.$route.query.lot) {
					this.checkUnit(product, null);
				}

				if (data.attachedUnit) this.step3.unit = data.attachedUnit.id ? data.attachedUnit.id : data.attachedUnit
				if (data.attachedPackage) this.step3.package = data.attachedPackage.id ? data.attachedPackage.id : data.attachedPackage
				this.drawLot = drawLot
				drawLot.initCanvas()
				await drawLot.initShape(data.siteplan, data.shapeId)
			}).catch((err) => {
				this.loadingUnits = false
				this.step3.unit = false
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					if (err != "TypeError: Cannot destructure property 'x' of 'this.shape' as it is undefined.") {
						this.$message.error(this.$err(err, 'Error while fetching unit list. Please select another unit'))
					}
				}
			})
		},
        'step3.package'(val) {
            if (!val) return
            // Show toast message if package is changed and it is from IPM but unit is not changed to avoid multiple messages
            if (this.isFromIPM && val != this.$route.query.package && this.$route.query.elevation == this.step3.unit) {
                this.$message.warn(this.productChangeMessage, 5)
            }
        },
    },
    methods:{
		checkUnit(unitId, packageId) {
			let foundUnit = this.units.findIndex(x => x.id == unitId);
			if (foundUnit != -1) {
				this.foundModel = true;
				this.step3.unit = unitId;
				this.step3.package = packageId || '';
			} else {
				this.foundModel = false;
				this.step3.unit = '';
				this.step3.package = '';
				this.$message.error('The elevation is no longer available for this lot. Please select another elevation.', 5);
			}
		},

        uploadCanvas(){
            return new Promise( (resolve,reject) => {
				if (!this.drawLot || !this.drawLot.lotBlob || !this.drawLot.siteBlob) {
					return resolve({ siteplanImage: this.step3.siteplanImage, lotImage: this.step3.lotImage });
				}
				let lotBlob = this.drawLot.lotBlob
				let siteBlob = this.drawLot.siteBlob
				let formData = new FormData()
				// Convert lotBlob to a Blob object if it's not already
				if (!(lotBlob instanceof Blob)) {
					lotBlob = new Blob([lotBlob], { type: 'image/jpeg' });
				}
				formData.append('force', 1);
				formData.append('files', lotBlob, `${this.step3.lot}.jpg`)
				formData.append('files', siteBlob, `siteplan-${this.step3.lot}.jpg`)
				// return resolve({ siteplanImage: this.step3.siteplanImage, lotImage: this.step3.lotImage })
				return this.$api.post('/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
					.then(({ data }) => {
						data.forEach(file => {
							if (file.name.includes('siteplan-')) this.step3.siteplanImage = file.url
							else this.step3.lotImage = file.url
						})
						return resolve({ siteplanImage: this.step3.siteplanImage, lotImage: this.step3.lotImage })
					}).catch(() => {
						reject()
					})
			})
		},
		checkClosing(date) {
			if (!date || !date.unix) return
			let time = date.unix() * 1000
			if (!isWorkingDay(time)) this.closingDateError = true
			else this.closingDateError = false
		},
		isWorkingDay,
		disabledDate(current) {
			return current && current < moment().startOf('day')
		},
		formatPrice: (p) => {
			if (!p) {
				return p;
			}
			if (typeof p === 'string') p = parseFloat(parseFloat(p).toFixed(2))
			if (!p) return `0`
			return p.toLocaleString('en-us')
		},
		req: msg => ({ required: true, message: msg }),
		validate() {
			let self = this

			this.$refs.step3.validate(async valid => {
				if (valid) {
					if (this.loadingUnits) {
						return
					}
					this.step
					this.$store.commit('LOAD', true)
					let sendObj = {}
					let timestamp = new Date(this.step3.closingDate).getTime()
					let isValidClosing = isWorkingDay(timestamp)

					if (!isValidClosing){
						this.$store.commit('LOAD', false)
						return this.closingDateError = true
					}
					else this.closingDateError = false
					let { tenancyStartDate, tenancyEndDate } = this.step3

					if (tenancyEndDate && tenancyEndDate.format) tenancyEndDate = tenancyEndDate.format('YYYY-MM-DD')
					if (tenancyStartDate && tenancyStartDate.format) tenancyStartDate = tenancyStartDate.format('YYYY-MM-DD')

					if (!this.currentPackage.other) this.currentPackage.other = { premiums: {} }
					if (!this.currentPackage.other.premiums) this.currentPackage.other.premiums = {}

					try {
						const payload = this.instance.productType === 'lowrise' ? { lot: this.step3.lot } : { unit: this.step3.unit }
						let { data } = await this.$api.post('/product-locks', payload)
						this.$store.commit('SET_PRODUCT_LOCK', { time: data.lockedUntil, product: this.instance.productType === 'lowrise' ? data.lot : data.unit })
					} catch (err) {
						this.$store.commit('LOAD', false)
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err, `Error occurred while accruing lock on selected ${this.instance.productType === 'lowrise' ? 'lot' : 'unit'}. Please try again!`))
						}
						return;
					}

					if (this.instance.productType === 'lowrise') {
						let { lotImage, siteplanImage } = await this.uploadCanvas()
						if (this.transaction.type == 'purchase') {
							let purchasePrice = this.step3.purchasePrice;

							if (this.transaction.offer && this.transaction.offer.purchasePrice && this.transaction.product &&
								(this.transaction.product.unit && this.transaction.product.unit.id) === (this.currentUnit && this.currentUnit.id)) {
								purchasePrice = this.transaction.offer.purchasePrice;
							}

							sendObj = {
								product: {
									unit: this.currentUnit,
									lot: {
										...this.currentLot,
										siteplan: this.currentSiteplan
									},
									siteplanImage,
									lotImage,
								},
								offer: {
									msrp: this.msrp,
									basePrice: this.currentPackage && this.currentPackage.price,
									lotPremiums: this.lotPremiums,
									closingType: this.step3.closingType,
									irrevocableDate: (this.notApplicableDates.irrevocableDate || !this.step3.irrevocableDate) ? 'N/A' : moment(this.step3.irrevocableDate).format('MMM-DD-YYYY'),
									closingDate: this.step3.closingDate && moment(this.step3.closingDate).format('MMM-DD-YYYY'),
									purchasePrice: purchasePrice
								},
							}
						} else {
							sendObj = {
								product: {
									unit: this.currentUnit,
									lot: {
										...this.currentLot,
										siteplan: this.currentSiteplan
									},
									siteplanImage: drawLot.siteplanImage,
									lotImage: drawLot.lotImage,
								},
								offer: {
									msrp: this.msrp,
									basePrice: this.currentPackage && this.currentPackage.price,
									lotPremiums: this.lotPremiums,
									totalRent: this.step3.totalRent,
									deposit: this.step3.deposit,
									tenancyStartDate,
									tenancyEndDate,
								},
							}
						}

						this.transaction.query.product = this.step3.unit
						this.transaction.query.floor = this.step3.lot

						if (!sendObj.product.lot.other) sendObj.product.lot.other = { premiums: {} }
						if (!sendObj.product.lot.other.premiums) sendObj.product.lot.other.premiums = {}

						Object.entries(sendObj.product.lot.other.premiums).forEach(([key, value]) => {
							if (!value.active) delete sendObj.product.lot.other.premiums[key]
						})


						sendObj.product.unit.package = this.currentPackage && { ...this.currentPackage }
						if (sendObj.product.unit.package && sendObj.product.unit.unitGroup) {
							sendObj.product.unit.unitGroup.name = sendObj.product.unit.unitGroup.name + ' - ' + sendObj.product.unit.package.name
						}

					} else if (this.instance.productType === 'highrise') {
						let floor = this.condoFloors.find(x => x.id === this.step3.floor)

						if (this.transaction.type == 'purchase') {
							sendObj = {

								product: {
									unit: this.currentUnit,
									floor,
								},
								offer: {
									msrp: this.msrp,
									basePrice: this.currentPackage && this.currentPackage.price,
									closingType: this.step3.closingType,
									irrevocableDate: (this.notApplicableDates.irrevocableDate || !this.step3.irrevocableDate) ? 'N/A' : moment(this.step3.irrevocableDate).format('MMM-DD-YYYY'),
									closingDate: (this.notApplicableDates.closingDate || !this.step3.closingDate) ? 'N/A' : moment(this.step3.closingDate).format('MMM-DD-YYYY'),
									terminationDate: (this.notApplicableDates.terminationDate || !this.step3.terminationDate) ? 'N/A' : moment(this.step3.terminationDate).format('MMM-DD-YYYY'),
									purchasePrice: this.step3.purchasePrice,
									outsideClosing: (this.notApplicableDates.outsideClosing || !this.step3.outsideClosing) ? 'N/A' : moment(this.step3.outsideClosing).format('MMM-DD-YYYY'),
								},
							}
						} else {
							sendObj = {

								product: {
									unit: this.currentUnit,
									floor,
								},
								offer: {
									msrp: this.msrp,
									basePrice: this.currentPackage && this.currentPackage.price,
									totalRent: this.step3.totalRent,
									deposit: this.step3.deposit,
									tenancyStartDate,
									tenancyEndDate,
								},
							}
						}
						if (!this.currentPackage.other) this.currentPackage.other = { premiums: {} }
						if (!this.currentPackage.other.premiums) this.currentPackage.other.premiums = {}
						sendObj.product.unit.package = { ...this.currentPackage }

                        const unitPackage = this.transaction.product?.unit?.package
                        if (unitPackage?.other && this.currentPackage.id === unitPackage.id) {
                            sendObj.product.unit.package.other = { ...sendObj.product.unit.package.other, ...unitPackage.other }
                        }

						this.transaction.query.product = this.step3.unit
						this.transaction.query.floor = this.step3.floor
					}

					// check for unit premiums and set the name and filter our inactive premiums

					if (sendObj.product.unit.package && sendObj.product.unit.package.other && sendObj.product.unit.package.other.premiums && Object.keys(sendObj.product.unit.package.other.premiums).length) {

						let newObj = {}
						Object.entries(sendObj.product.unit.package.other.premiums).forEach(([key, value]) => {
							if (value && value.active) {
								let { value: price } = value
								if (!price) price = 0
								newObj[key] = {
									id: key,
									active: true,
									value: +price,
									name: this.highRisePremiums[key]
								}
							}
						})
						sendObj.product.unit.package.other.premiums = newObj
					}
					this.$store.commit('LOAD', false)

					if (sendObj.offer) {
						sendObj.offer.bricks = [];
						if (this.brickChosen) {
							sendObj.offer.bricks.push(this.brickChosen)
						}
					}
					self.$emit('done', sendObj)
				}
			})
		},

		onNotApplicableChange(event, dateField) {
			if (event.target && event.target.checked) {
				this.step3[dateField] = 'N/A'
			} else {
				this.step3[dateField] = null
			}
		},

		checkUnitAvailability(unitId) {
			return this.floorUnits.find(u => u.id === unitId && u.salesStatus !== 'sold')
		},

		checkLotAvailability(lotId) {
			return this.lots.find(u => u.id === lotId)
		}
	},
	created() { },
	mounted() {
		if (this.instance.productType === 'lowrise') {
			this.$api.get(`/architectural-controls/:instance/availablelots`).then(({ data }) => {
				let lots = data
				if (this.$route.params.id && this.transaction.product && this.transaction.product.lot) {
					const found = lots.find(l => l.id == this.transaction.product.lot.id)
					if (!found) {
						lots.push(this.transaction.product.lot)
					}
				}
				this.lots = lots.sort((a, b) => {
					//convert a.name and b.name to a number and then sort by lowest number to highest number
					return +a.name - +b.name
				})
				let { product, floor } = this.transaction.query
				if (Object.values(this.$route.query).length && product && product.length) {
					let foundProduct = this.lots.find(x => x.id === floor)
					if (foundProduct) {
						this.step3.lot = floor
						this.foundLot = true
					} else {
						this.productNotFound = true
						this.foundLot = false
						this.$message.error('Could not find the lot linked to Opportunity. Please select another lot', 5)
					}
				}

				// Set Lot and Unit value if transaction is creating from submitted worksheet
				if (this.worksheet) {
					this.step3.lot = this.worksheet.lot.id;
					// Adding timeout to calculate depended computed value properly
					setTimeout(() => {
						if (this.checkUnitAvailability(this.worksheet.unit.id)) {
							this.step3.unit = this.worksheet.unit.id;
						}
					}, 100)
				}

				// Set Floor and Unit value if transaction is creating from submitted reservation
				if (this.reservation) {
					let lotId = this.reservation.lot.id
					let foundProduct = this.lots.find(x => x.id === lotId)
					if (foundProduct) {
						this.step3.lot = lotId
						this.foundLot = true
					} else {
						this.productNotFound = true
						this.foundLot = false
						this.$message.error('Could not find the lot linked to reservation. Please select another lot', 5)
					}
				}

				if (this.transaction && this.transaction.product && this.transaction.product.lot) {
					if (this.checkLotAvailability(this.transaction.product.lot.id)) {
						this.step3.lot = this.transaction.product.lot.id;
					}
				}
			}).catch((err) => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
		} else {
			this.$api.get('/settings/:instance/condounits').then(({ data }) => {
				if (data) {
					this.$store.commit('SET_CONDO_SETTINGS', data)
				}
				let premiums = [
					{
						name: 'Floor Premium',
						value: '',
						id: 'floorPremium',
					},
					{
						name: 'View Premium',
						value: '',
						id: 'viewPremium',
					},
					{
						name: 'Balcony Premium',
						value: '',
						id: 'balconyPremium',
					},
					{
						name: 'Terrace Premium',
						value: '',
						id: 'terracePremium',
					},
				]
				if (data && data.options && data.options.premiums && data.options.premiums.length) {
					premiums = [...premiums, ...data.options.premiums]
				}
				premiums.forEach(prm => {
					this.highRisePremiums[prm.id] = prm.name
				})
			}).catch((err) => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
			this.$api.get(`/unit-groups/:instance`).then(({ data }) => {
				data = data.filter(x => x.buildingType === 'condounits').map(floor => {
					floor.sold = true
					floor.disabled = true
					if (floor.units.length) floor.disabled = false
					floor.units = floor.units.filter(x => x.salesStatus && !x.salesStatus.includes('sold'))
					if (floor.units.length) floor.sold = false
					return floor
				})
				this.condoFloors = data
				let { product, floor } = this.transaction.query
				if (floor?.length && product?.length) {
					this.$store.commit('LOAD', true)
					try {
						this.step3.floor = floor
						this.$nextTick(() => {
							setTimeout(() => {
								if (this.checkUnitAvailability(product)) {
									this.step3.unit = product
								}
								this.$store.commit('LOAD', false)
							}, 20);
						})
					} catch (err) {
						this.$store.commit('LOAD', false)
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err, 'Could not find the floor/unit linked to Opportunity. Please select another unit'))
						}
					}
				}

				// Set Floor and Unit value if transaction is creating from submitted worksheet
				if (this.worksheet?.unit) {
					this.step3.floor = this.worksheet.unit.unitGroup;
					// Adding timeout to calculate depended computed value properly
					setTimeout(() => {
						if (this.checkUnitAvailability(this.worksheet.unit.id)) {
							this.step3.unit = this.worksheet.unit.id;
						}
					}, 100)
				}

				// Set Floor and Unit value if transaction is creating from submitted reservation
				if (this.reservation) {
					this.step3.floor = this.reservation.unit.unitGroup;
					// Adding timeout to calculate depended computed value properly
					setTimeout(() => {
						if (this.checkUnitAvailability(this.reservation.unit.id)) {
							this.step3.unit = this.reservation.unit.id;
						}
					}, 100)
				}

				if (this.transaction.product && this.transaction.product.floor && this.transaction.product.unit) {
					this.step3.floor = this.transaction.product.floor.id;
					// Adding timeout to calculate depended computed value properly
					setTimeout(() => {
						if (this.checkUnitAvailability(this.transaction.product.unit.id)) {
							this.step3.unit = this.transaction.product.unit.id;
							this.step3.package = this.transaction.product.unit.package && this.transaction.product.unit.package.id || ''
						}
					}, 100)
				}
			}).catch((err) => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
		}

		if (this.appSettings.irrevocableDateDays) {
			this.step3.irrevocableDate = moment().add(this.appSettings.irrevocableDateDays, 'day')
		}

		if (this.appSettings.naIrrevocableDate) {
			this.notApplicableDates.irrevocableDate = true;
			this.step3.irrevocableDate = 'N/A'
		}
		if (this.appSettings.naClosingDate) {
			this.notApplicableDates.closingDate = true;
			this.step3.closingDate = 'N/A'
		}
		if (this.appSettings.naTerminationDate) {
			this.notApplicableDates.terminationDate = true;
			this.step3.terminationDate = 'N/A'
		}
		if (this.appSettings.naOutsideClosing) {
			this.notApplicableDates.outsideClosing = true;
			this.step3.outsideClosing = 'N/A'
		}

		if (this.transaction.offer) {
			if (this.transaction.offer.irrevocableDate === 'N/A') {
				this.notApplicableDates.irrevocableDate = true;
				this.step3.irrevocableDate = 'N/A'
			} else if (this.transaction.offer.irrevocableDate) {
				this.notApplicableDates.irrevocableDate = false;
				this.step3.irrevocableDate = moment(this.transaction.offer.irrevocableDate);
			}

            if (this.transaction.offer.closingDate === 'N/A') {
                this.notApplicableDates.closingDate = true;
                this.step3.closingDate = 'N/A'
            } else if (this.transaction.offer.closingDate) {
				this.notApplicableDates.closingDate = false;
                this.step3.closingDate = moment(this.transaction.offer.closingDate);
            }

            if (this.transaction.offer.outsideClosing === 'N/A') {
                this.notApplicableDates.outsideClosing = true;
                this.step3.outsideClosing = 'N/A'
            } else if (this.transaction.offer.outsideClosing) {
				this.notApplicableDates.outsideClosing = false;
                this.step3.outsideClosing = moment(this.transaction.offer.outsideClosing);
            }

			if (this.transaction.offer.terminationDate === 'N/A') {
                this.notApplicableDates.terminationDate = true;
                this.step3.terminationDate = 'N/A'
            } else if (this.transaction.offer.terminationDate) {
				this.notApplicableDates.terminationDate = false;
                this.step3.terminationDate = moment(this.transaction.offer.terminationDate);
            }

			this.step3.tenancyStartDate = this.transaction.offer.tenancyStartDate && moment(this.transaction.offer.tenancyStartDate) || null
			this.step3.tenancyEndDate = this.transaction.offer.tenancyEndDate && moment(this.transaction.offer.tenancyEndDate) || null
			this.step3.purchasePrice = this.transaction.offer.purchasePrice || 0
			this.step3.totalRent = this.transaction.offer.totalRent || 0
			this.step3.deposit = this.transaction.offer.deposit || 0
			this.step3.closingType = this.transaction.offer.closingType || 'tentative'
			this.step3.brick = this.transaction.offer.bricks && this.transaction.offer.bricks[0] && this.transaction.offer.bricks[0].id || ''
		}

		this.$emit('setFn', this.validate)
	}
}
</script>

<style></style>
